import React, { useMemo, useState } from "react";

export default function FormContentWrapper({
  FormContent,
  submit,
  propertiesList,
  handleStepChange,
  preSelectedPropertySlug,
}) {
  // Find the index of the preselected property if it exists
  const preSelectedPropertyIndex = useMemo(() => {
    return propertiesList.findIndex(
      (property) => property.publicPageSlug === preSelectedPropertySlug
    );
  }, [preSelectedPropertySlug, propertiesList]);

  // Create a new list of properties with the preselected property at the top
  const properties = useMemo(() => {
    const newPropertiesList = propertiesList || [];

    // Remove the preselected property from the list
    const [property] = newPropertiesList.splice(preSelectedPropertyIndex, 1);

    // Add the preselected property to the top of the list
    newPropertiesList.unshift(property);
    return newPropertiesList;
  }, [propertiesList, preSelectedPropertyIndex]);

  // Initialize the selected property ids with the preselected property id
  const [selectedPropertyIds, setSelectedPropertyIds] = useState(
    preSelectedPropertyIndex !== -1 ? [properties[0].id] : []
  );

  const isSelectedAll = useMemo(() => {
    return selectedPropertyIds.length === properties.length;
  }, [selectedPropertyIds, properties]);

  const toggleSelectAll = () => {
    if (isSelectedAll) {
      setSelectedPropertyIds([]);
    } else {
      setSelectedPropertyIds(properties.map(({ id }) => id));
    }
  };

  return (
    <FormContent
      {...{
        submit,
        properties,
        handleStepChange,
        isSelectedAll,
        selectedCardIds: selectedPropertyIds,
        toggleSelectAll,
        setSelectedCardIds: setSelectedPropertyIds,
      }}
    />
  );
}
