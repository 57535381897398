import React from "react";
import Link from "next/link";
import { Button, useDisclosure, ButtonProps } from "@chakra-ui/react";
import InterestListModal from "home/components/interest_list/InterestListModal";
import { generateUrl } from "home/lib/utils";
import { buttonVariant } from "./pagesCfg";
import { CMSButton } from "./CmsTypes";

interface ButtonManagedProps extends ButtonProps {
  setup: CMSButton;
}

const btnStyles = {
  h: "54px",
  px: 12,
  py: 4,
  lineHeight: 1.4,
};

const ButtonManaged = ({ setup, ...rest }: ButtonManagedProps) => {
  const modal = useDisclosure();
  if (!setup) return null;
  const { url, page, modal_interest_list, title, variant } = setup;
  if (modal_interest_list) {
    return (
      <>
        <Button
          onClick={modal.onOpen}
          cursor="pointer"
          variant={buttonVariant[variant]}
          {...rest}
          {...btnStyles}
        >
          {title}
        </Button>
        {modal.isOpen ? (
          <InterestListModal {...modal} preSelectedPropertySlug={url} />
        ) : null}
      </>
    );
  } else if (url || page?.data?.attributes?.slug) {
    return (
      <Link
        href={url || generateUrl(page?.data?.attributes?.slug)}
        passHref
        legacyBehavior
      >
        <Button
          as="a"
          target={url ? "_blank" : "_self"}
          variant={buttonVariant[variant]}
          {...rest}
          {...btnStyles}
        >
          {title}
        </Button>
      </Link>
    );
  } else {
    return (
      <Button variant={buttonVariant[variant]} {...rest} {...btnStyles}>
        {title}
      </Button>
    );
  }
};

export default ButtonManaged;
