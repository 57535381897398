import React, { useState } from "react";
import {
  Box,
  Text,
  Modal,
  Heading,
  ModalBody,
  ModalContent,
  ModalOverlay,
  ModalCloseButton,
  ModalHeader,
  Spinner,
  Center,
} from "@chakra-ui/react";
import { getActiveStepById, ModalSteps } from "./modalConfig";
import useTranslation from "next-translate/useTranslation";
import { useInterestList } from "home/lib/interestlist";
import FormContentWrapper from "./InterestListFormContentWrapper";

const InterestListModal = ({ isOpen, onClose, preSelectedPropertySlug }) => {
  const { t } = useTranslation("common");
  const { isLoading, isError, properties, submit } = useInterestList();

  const [activeStep, setActive] = useState(
    getActiveStepById(ModalSteps.INTEREST_CARDS, t)
  );

  const handleStepChange = (stepId) => setActive(getActiveStepById(stepId, t));

  const handleClose = () => {
    onClose();
    handleStepChange(ModalSteps.INTEREST_CARDS);
  };

  const FormContent = activeStep.component;

  console.log(properties);

  return (
    <Modal
      isOpen={isOpen}
      onClose={handleClose}
      size="2xl"
      scrollBehavior="inside"
    >
      <ModalOverlay />
      <ModalContent
        mt={[0, 2, 12]}
        mb={[0, 2, 12]}
        mx={[0, 2, 0]}
        borderRadius="0"
        maxH={["100vh", "calc(100vh - 1.5rem)", "calc(100vh - 7rem)"]}
      >
        <ModalCloseButton top={6} right={6} size="xl" color="white" />
        <ModalHeader px="0" py="0">
          <Box py={6} pr={16} pl={[4, 4, 6]} bg="blueGray.800">
            {isError ? (
              <Heading size="sm" color="white">
                An error occurred
              </Heading>
            ) : (
              <>
                <Heading mb={2} size="sm" color="white">
                  {activeStep.title}
                </Heading>
                <Text textStyle="subtitleNormal" color="white">
                  {activeStep.subTitle}
                </Text>
              </>
            )}
          </Box>
        </ModalHeader>
        <ModalBody borderRadius="0">
          {isLoading ? (
            <Center my="10">
              <Spinner />
            </Center>
          ) : isError ? (
            <Center my="10">
              <Text>Something unexpected went wrong, please try again</Text>
            </Center>
          ) : (
            <FormContentWrapper
              FormContent={FormContent}
              submit={submit}
              propertiesList={properties}
              handleStepChange={handleStepChange}
              preSelectedPropertySlug={preSelectedPropertySlug}
            />
          )}
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

export default InterestListModal;
